export const environment = {
  production: true,
  apiUrl: 'https://api.master.qarbcompanion.com',
  masterUrl: 'https://master.qarbcompanion.com',
  auth: {
    clientId: 'b1bd58e1-a6f7-4ed7-ab91-15b86dea45f2', // This is your client ID
    authority: 'https://login.microsoftonline.com/4311f7b0-1d4f-429a-b2da-ec3498aded90', // This is your tenant ID
    redirectUri: 'https://employee.qarbcompanion.com/'// This is your redirect URI
  },
};
